import React, { useState } from 'react'
import { CSSTransition } from 'react-transition-group';
import classNames from "classnames"
import "react-datepicker/dist/react-datepicker.css";
import Alert from "../components/alert"
import Autocomplete from "../components/autocomplete"

const TransitionDestiDestete = props => {
  
  const [destiDesteteSelected,setDestiDesteteSelected] =useState(0);
  const [destiDesteteStringSelected, setDestiDesteteStringSelected] = useState("");
  const [nauSelected, setNauSelected] = useState("");
  const [nauStringSelected, setNauStringSelected] = useState("");
  const [isNauVisible, setNauVisible] = useState(false);
  const [naus, setNaus] = useState([]);
  const [showAlertStep, setShowAlertStep] = useState(false);
  const [messageAlertStep, setMessageAlertStep] = useState("");

  const handleNauSelected= (e) =>{
    setNauSelected(e.target.value)
    setNauStringSelected(e.target.options[e.target.selectedIndex].label)
  }
  const handleDestiDesteteChange= (text,index) =>{
    let granja= text.split("-");
    let code= granja[0].trim();
    let granjaText=granja[1].trim()
    code = code.replace(/^\s+/g, '');
    granjaText=granjaText.replace(/^\s+/g, '');
    if (index<0 || index===undefined) {
      setNaus([]);
      setNauSelected("")
      setDestiDesteteSelected(code)
      setDestiDesteteStringSelected("");
      setNauVisible(false)
      return
    }
    setDestiDesteteSelected(code)
    setDestiDesteteStringSelected(code+"-"+granjaText)
    console.log("desti destete selected = "+code+ ", "+granjaText)
    console.log("naus del index "+index)
    console.log(JSON.stringify(props.arrayData))
    setNaus(props.arrayData[index].naus);

    if (props.arrayData[index].naus.length>1) {
      setNauVisible(true)
    } else {
      setNauVisible(false)
      // seleccionem la primera
      setNauSelected(props.arrayData[index].naus[0].idNau)
      setNauStringSelected(props.arrayData[index].naus[0].nomNau)
    } 
  }
 
  const closeAlertStep = event => {
    setShowAlertStep(false)
  }
  const onExit = () =>{
    var res=[];
    res.push({
      "key":"Desti",
      "value": destiDesteteStringSelected
    })
    res.push({
      "key":"Nau",
      "value": nauStringSelected
    })
    props.callbackValues.destiDesteteSelected=destiDesteteSelected;
    props.callbackValues.nauSelected=nauSelected;
    props.callbackValues.isNauVisible=isNauVisible;
    props.callbackStrings(res)
  }

  const handleClickUp = (e) => {
    e.preventDefault();
    if(isNauVisible && !nauSelected){
      setMessageAlertStep("Has de seleccionar una nau")
      setShowAlertStep(true)
      return
    }
    if(!isNauVisible && !destiDesteteSelected ){
      setMessageAlertStep("Has de seleccionar un Destí Destete")
      setShowAlertStep(true)
      return
    }
    setShowAlertStep(false)
    props.handleStepActiveUp(props.stepActive)
  }

  const handleClickDown = (e) => {
    e.preventDefault();
    props.handleStepActiveDown(props.stepActive)
  }

  const objectGranjes = Object.entries(props.arrayData);
  var ArrayGranjesAutocomplete = [];
  objectGranjes.forEach(([key, value]) => {
    var item = {};
    item.value = value.marca;
    item.label = value.marca + "-" + value.nomMarca;
    ArrayGranjesAutocomplete.push(item);
  });

  return (
   <span>   
      <CSSTransition
        in={props.stepActive === props.index}
        timeout={300}
        classNames={props.stepPrev<props.stepActive?'steptransitionright':'steptransitionleft'}
        unmountOnExit
        onExit={onExit}
      >
      <div className="steps-panel">
                
        <Autocomplete suggestions={ArrayGranjesAutocomplete} inputLabel="Destete" inputName="desti-destete" callbackValue={handleDestiDesteteChange}/>
         {
            isNauVisible && (
            <div className="field-wrapper field-wrapper-select">
              <select className={nauSelected && 'dirty'} name="nau" required id="select" value={nauSelected} onChange={handleNauSelected}>
                <option value=""></option>
                { naus.map((nau) => (
                  <option key={nau.idNau} value={nau.idNau}>{nau.nomNau}</option>
                )) 
                }
              </select>
              <label htmlFor="naus">Naus</label>
            </div>
            )
          }
   
        <div className="steps-nav">
          <button onClick={handleClickDown} className={props.stepActive <= 1 ? classNames('disabled', 'steps-nav-btn', 'steps-nav-btn-prev') : classNames('steps-nav-btn', 'steps-nav-btn-prev')}>
            <span>Prev</span>
          </button>
          <button onClick={handleClickUp} className={props.stepActive >= 5 ? classNames('disabled', 'steps-nav-btn steps-nav-btn-next') : 'steps-nav-btn steps-nav-btn-next'}>
            <span>Next</span>
          </button>
        </div>
      </div>
    </CSSTransition>
    <CSSTransition
    in={showAlertStep}
    timeout={300}
    classNames={'alertright'}
    unmountOnExit
  >
    <Alert type="error">
      <div className="alert-message">
      {messageAlertStep}
      </div>
      <span className="alert-close" onClick={closeAlertStep}>
        <i className="icon-close"></i>
      </span>
    </Alert>
  </CSSTransition>
</span>
  )
}
export default TransitionDestiDestete